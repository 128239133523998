import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import Clipboard from "@ryangjchandler/alpine-clipboard"
import ToastComponent from '../../vendor/usernotnull/tall-toasts/resources/js/tall-toasts';
import Chart from 'chart.js/auto';
import jQuery from "jquery";
import Popper from 'popper.js';
import breakpoint from 'alpinejs-breakpoints'


Alpine.plugin(breakpoint)
Alpine.plugin(collapse)
Alpine.plugin(Clipboard)
Alpine.plugin(ToastComponent)

window.Alpine = Alpine;
window.Chart = Chart;
window.Popper = Popper;
window.$ = window.jQuery = jQuery;

// Vendor Components...
import 'scrollpos-styler';
import 'bootstrap';

Alpine.start();

import './vendor/distortedfusion/blade-components/dropdown-placement.js';
import './vendor/distortedfusion/blade-components/js-detection.js';
import './vendor/distortedfusion/blade-components/sidebar.js';
import './vendor/distortedfusion/blade-components/tooltip.js';

// Application Components...
import './components/jquery/stripe-card.js';
